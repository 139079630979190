import React from 'react'
import './less/honorItem.less'

class HonorItem extends React.PureComponent {
  honerClass = listLength => {
    let name = 'honor-content'
    if (listLength === 1) {
      name = 'honor-content honor-content-one'
    }
    if (listLength === 2) {
      name = 'honor-content honor-content-two'
    }
    return name
  }

  render() {
    const { list, index } = this.props
    return (
      <div className='honor-box'>
        <div className='honor-pic'>
          <img className={`honor-img honor-img${index + 1}`} src={list.img} />
        </div>
        <div className={this.honerClass(list.content.length)}>
          {list.content &&
            list.content.length > 0 &&
            list.content.map((item, indexText) => (
              <div className='honor-text' key={`${indexText + 1}`}>
                {item}
              </div>
            ))}
        </div>
      </div>
    )
  }
}

export default HonorItem
