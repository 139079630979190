/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { enquireScreen } from 'enquire-js'
import { graphql } from 'gatsby'
import { Row, Col } from 'antd'

import HonorItem from '../../components/antd/HonorItem'
import Layout from '../../components/layout'
import '../../components/antd/less/antMotionStyle.less'
import SEO from '../../components/seo'

import pic2 from '../../images/honer1.jpg'
import pic3 from '../../images/honer2.jpg'
import pic4 from '../../images/honer3.jpg'
import pic5 from '../../images/honer4.jpg'
import pic6 from '../../images/honer5.jpg'
import pic7 from '../../images/honer6.jpg'
import pic8 from '../../images/honer7.jpg'
import pic9 from '../../images/honer8.jpg'
import pic10 from '../../images/honer9.jpg'
import pic11 from '../../images/honer10.jpg'
import pic12 from '../../images/honer11.jpg'
import pic13 from '../../images/honer12.jpg'
import pic14 from '../../images/honer13.jpg'

let isMobile
enquireScreen(b => {
  isMobile = b
})

const listdata = [
  {
    img: pic2,
    content: ['2020年智慧农业', '“种子工程”T0P30案例奖'],
  },
  {
    img: pic3,
    content: ['2020可信区块链峰会', '十大高价值案例'],
  },
  {
    img: pic4,
    content: ['2020农产品供应链', '创新与应用案例奖'],
  },
  {
    img: pic5,
    content: [
      '基于人工智能+区块链',
      '的茶纹溯源应用获2020',
      '中国产业区块链创新奖',
    ],
  },
  {
    img: pic6,
    content: ['通过ISO90001', '质量管理体系认证'],
  },
  {
    img: pic7,
    content: ['通过ISO20000IT', '服务管理体系认证'],
  },
  {
    img: pic8,
    content: ['通过ISO27001信息', '安全管理体系认证'],
  },
  {
    img: pic9,
    content: [
      'A1系统深度学习加速器',
      '获IEE/ACMI顶级会议',
      'ICCAD2018最佳论文',
    ],
  },
  {
    img: pic10,
    content: [
      '“茶纹链” 名列国家网信办',
      '发布2020年境内区块链',
      '信息服务备案清单(第四批)',
    ],
  },
  {
    img: pic11,
    content: ['中国防伪行业协会', '认证防伪技术评审'],
  },
  {
    img: pic12,
    content: [
      '2021年全球人工智能Kaggle',
      '(植物病虫害识别)金牌奖',
      '(全球超过3900支参赛队伍)',
    ],
  },
  {
    img: pic13,
    content: [
      '荣登2020年两次全球',
      '最大的区块链开源项目',
      '(Hyperledger) 贡献榜',
    ],
  },
  {
    img: pic14,
    content: ['荣获2021中国产业', '区块链优秀案例'],
  },
]

export default class Home extends React.Component {
  constructor(...props) {
    super(...props)
    this.state = {
      isMobile,
      current: 1,
      showNewsData: [],
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
    this.getNewsData(1)
  }

  onChange = page => {
    this.setState({ current: page })
    this.getNewsData(page)
  }

  getNewsData = current => {
    const {
      data: { strapi = {} },
    } = this.props
    const { news = [] } = strapi
    const newNews = news.sort((a, b) => {
      return Date.parse(b.publish_date) - Date.parse(a.publish_date)
    })
    this.setState({
      showNewsData: newNews.slice((current - 1) * 8, (current - 1) * 8 + 8),
    })
  }

  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={4}
        products={products}
        hasBg={false}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='荣誉' />
        <div className='honor-wrap'>
          <div className='main-img'></div>
          <div className='main-con'>
            <h2>荣誉墙</h2>
            <Row>
              {listdata &&
                listdata.map((item, index) => (
                  <Col
                    className='news-item-wrap'
                    key={`${index + 1}`}
                    md={6}
                    xs={24}
                    style={{ padding: '1rem' }}
                  >
                    <HonorItem list={item} index={index} />
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    strapi {
      news(sort: "publish_date") {
        id
        title
        publish_date
        short_description
        link
        tags {
          id
          key
        }
        img {
          url
        }
      }
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
